<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3>Are You Sure?</h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div>{{message}}</div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button type="button" class="app-button primary-button" v-on:click="confirmYes">Confirm</button>
              <button type="button" class="app-button cancel-button" v-on:click="confirmNo">Cancel</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: String,
    level: String
  },
  methods: {
    confirmYes(){
      this.$emit('confirm', {response: true, level: this.level})
    },
    confirmNo(){
      this.$emit('confirm', {response: false})
    }
  }
};
</script>

<style>
.cancel-button{
  color:black;
}
.modal-mask{
  text-align: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.modal-container {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 0.2rem solid rgb(170, 0, 0);
  width: 80%; /* Could be more or less, depending on screen size */
}

.modal-footer{
  padding: 1rem;
  margin-left:2rem;
  margin-right: 2rem;
}

.success {
  border: 2px solid green;
  font-weight: bold;
  padding: 0.5rem;
  color: rgb(191, 255, 212);
}

.danger {
  border: 2px solid red;
  font-weight: bold;
  padding: 0.5rem;
  color:rgb(248, 228, 228);
}
.close {
  position: absolute;
  right:1rem;
  width: 2rem;
  height: 2rem;
  border: 0.15rem solid rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  margin-top:-0.5rem;
}
.close:hover {
  opacity:0.8;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}


</style>
